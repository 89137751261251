import { gql } from '@apollo/client';

export const OPPORTUNITY_REFUND_REQUESTS_QUERY = gql`
  query opportunityRefundRequests($opportunity: String!, $first: Int!, $page: Int) {
    opportunityRefundRequests(opportunitySlug: $opportunity, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        status
        reason
        message
        created_at
        application {
          name
          id
        }
      }
    }
  }
`;

export const APPROVE_REFUND_REQ_MUTATION = gql`
  mutation approveRefundRequest($id: ID!) {
    approveRefundRequest(id: $id) {
      id
      status
      reason
      message
      created_at
    }
  }
`;

export const REFUND_APLICATION_MUTATION = gql`
  mutation refundApplication($id: ID!) {
    refundApplication(id: $id) {
      
      status
     
    }
  }
`;

export const DECLINE_REFUND_REQ_MUTATION = gql`
  mutation declineRefundRequest($id: ID!) {
    declineRefundRequest(id: $id) {
      id
      status
      reason
      message
      created_at
    }
  }
`;
