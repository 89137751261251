import { rgba } from 'polished';

export const colors = Object.freeze({
  primary: {
    light: '#ff857e',
    main: '#f35252',
    dark: '#ba1429',
    contrastText: '#ffffff',
  },

  secondary: {
    light: '#E0E0E0',
    main: '#757575',
    dark: '#4f4d4d',
    contrastText: '#ffffff',
  },
  green: '#4CAF50',
  blue: '#2196F3',
  yellow: '#FFC107',
  // red: '#F44336',
  lightGray: '#ecf0f1',
  white: '#fff',
  lightSilver: '#fafafa',
  secondaryColor: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    150: '#DADADA',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  grey: {
    200: '#b0bec5',
    300: '#90a4ae',
    400: '#78909c',
    500: '#607d8b',
    600: '#546e7a',
    700: '#455a64',
    800: '#37474f',
    900: '#263238',
  },
  greenShades: {
    50: '#edf8e9',
    100: '#d2eec7',
    200: '#b5e2a3',
    300: '#96d67e',
    400: '#7ecd61', // secondary main
    500: '#66c445',
    600: '#57b43d',
    700: '#42a033',
    800: '#2d8b29',
    900: '#006918',
    A100: '#F8FFF6',
    A200: '#D0FFC3',
    A400: ' #A7FF90',
    A700: '#93FF77',
  },
  red: {
    50: '#FFD4D4',
    100: '#FFABAB',
    200: '#FF7D7D',
    300: '#FA6363',
    400: '#F35252',
    500: '#ED4545',
    600: '#EA3737',
    650: '#d32f2f',
    700: '#D72C31',
    800: '#CA252A',
    900: '#BB181E',
    A100: '#FFFFFF',
    A200: '#FFF4F4',
    A400: '#FFC1C1',
    A700: '#FFA7A7',
  },
  common: {
    black_60: '#666',
    black: '#000',
    rating: '#ffb400',
    footer: '#1f2326',
    inBlack: rgba('#000000', 0.54),
    divider: rgba('#212121', 0.15),
  },
  blueGrey: {
    300: '#90A4AE',
    400: '#78909C',
  },
});
