import styled from 'styled-components';
import { Snackbar as MuiSnackbar } from '@mui/material';

export const StyledSnackbar = styled(MuiSnackbar)`

  .MuiSnackbarContent-root {

   
    background-color: ${({ theme, type }) => {
      let color = null;
      switch (type) {
        case 'success':
          color = theme.green;
          break;

        case 'info':
          color = theme.blue;
          break;

        case 'loading':
          color = theme.blue;
          break;

        case 'warning':
          color = theme.yellow;
          break;

        case 'error':
          // eslint-disable-next-line prefer-destructuring
          color = theme.red[500];
          break;

        default:
          color = theme.blue;
          break;
      }

      return color;
    }};
  }
  .MuiSnackbarContent-message { 
    max-width: 30rem;

  }
`;

export const TypedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .message-container {
    margin-left: 1rem;
  }
  svg {
    color: white;
  } 
 
`;

export const MessageContainer = styled.div`
margin-left: 1rem;
`;
