import { gql } from '@apollo/client';

const TOGGLE_SIDEBAR_FRAGMENT = gql`
  fragment toggleSidebar on CommonState {
    sidebarOpened
  }
`;

const USER_INFO_FRAGMENT = gql`
  fragment UserInformation on User {
    id
    name
    email

    avatar {
      name
      path
      thumb
      placeholder
    }
    locale
    role {
      name
      display_name
    }
    selected_type
    genres {
      name,
      slug,
    },
    instruments{
      instrument {
        name,
        slug,
      },
      skill_level,
    },
    types{
      name,
      slug,
    },
    dob,
    about,
    professional_level,
    city,
    country,
    phone,
    gender,
    settings {
        cur_pref,
        lang_pref,
        allow_contact,
      },
  }
`;

const PAGINATOR_FRAGMENT = gql`
  fragment paginator on PaginatorInfo {
    count
    currentPage
    firstItem
    hasMorePages
    lastItem
    lastPage
    perPage
    total
  }
`;

const LOCATION_FRAGMENT = gql`
  fragment locationInfo on Location {
    id
    formatted_address
    name
    reference_id
    city
    country
    # country_code
  }
`;

export const COUNTRIES_INFO = gql`
  fragment countriesInfo on Country {
    id
    name
    code
  }
`;

const CommonFragments = {
  toggleSidebar: TOGGLE_SIDEBAR_FRAGMENT,
  userInfo: USER_INFO_FRAGMENT,
  paginator: PAGINATOR_FRAGMENT,
  locationInfo: LOCATION_FRAGMENT,
  countriesInfo: COUNTRIES_INFO

};

export default CommonFragments;
