// MutationsStatus.js
import React, { useEffect } from 'react';
import { useTranslation } from 'config/i18n';
import { ERRORS_CATEGORIES } from 'constants/errors';
import PropTypes from 'prop-types';
import {useSnackbar} from '@app/providers/SnackbarProvider';
import Snackbar from '../Snackbar';

const SnackNotifications = ({ loading, error, success, errorMessage, successMessage, }) => {
  const { t } = useTranslation(['common']);
  const { showSnackbar, hideSnackbar } = useSnackbar();
  let type = 'loading';
  
  const getMessage = (snackType) => {
    let message = [];
    if (snackType === 'loading') {
      message = [<div>{t('loading')}</div>];
    }
    if (snackType === 'success') {
      message = [<div>{t('success')}</div>];
    }
    if (type === 'error') {
      const {
        graphQLErrors = null,
        message: graphMessage = null,
      } = error || {};
      
      message = [<>{errorMessage || t('sorry.error')}</>];

      if (graphQLErrors && graphQLErrors.length > 0) {
        // iterate all GraphQL errors
        for (let i = 0; i < graphQLErrors.length; i += 1) {
          // Check error type:
          switch (graphQLErrors[i].extensions.category) {
            // If it's a validation error display validation message
            case ERRORS_CATEGORIES.VALIDATION: {
              message = [
                // <>
                //   <div>{graphMessage}</div>
                //   <br />
                // </>,
              ];
              const validationMessages = Object.values(
                graphQLErrors[i].extensions.validation
              ).reduce((acc, val) => [...acc, ...val]);

              message.push(
                validationMessages.map((msg) => (
                  <>
                    {msg}
                    <br />
                  </>
                ))
              );
              break;
            }
            //  if it's an authentication error display the message from the server.
            case ERRORS_CATEGORIES.AUTHENTICATION:
              message = [<div>{graphMessage}</div>];

              break;

            case ERRORS_CATEGORIES.BAD_REQUEST:
              message = [<div>{graphMessage}</div>];

              break;

            // otherwise display the default message:
            default:
              message = [<>{t('sorry.error')}</>];
              break;
          }
        }
      }
    }
    return <div>{message}</div>;
  };

  useEffect(() => {
    if (error) {
      type = 'error';
    }

    if (success) {
      type = 'success';
    }

    if (loading || error || success) {
      showSnackbar(type, getMessage(type), );
    } else {
      hideSnackbar();
    }
  }, [loading, error, success]);

  return null;
};

SnackNotifications.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.object,
  error: PropTypes.object,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
};

SnackNotifications.defaultProps = {
  loading: undefined,
  error: undefined,
  success: undefined,
  errorMessage: null,
  successMessage: null,
};

export default SnackNotifications;
