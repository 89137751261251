import Snackbar from '@app/components/molecules/Snackbar';
import React, { createContext, useContext, useState } from 'react';

const SnackbarContext = createContext();

// eslint-disable-next-line react/prop-types
const SnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const showSnackbar = (type = 'success', message) => {
    setSnackbarOpen(true);
    if (message) {
      setSnackbarMessage(message);
    }
    setSnackbarType(type);
  };

  const hideSnackbar = () => {
      setSnackbarMessage('');
      setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider
      value={{ snackbarOpen, showSnackbar, hideSnackbar, snackbarType, snackbarMessage }}
    >
      {snackbarOpen && (
        <Snackbar
          type={snackbarType}
          open={snackbarOpen}
          message={snackbarMessage}
          close={snackbarType !== 'loading'}
          autoHideDuration={3000}
          onClose={hideSnackbar}
        />
      )}
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);

export default SnackbarProvider;
