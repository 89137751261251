import {  gql } from '@apollo/client';

import { FILLTER_KEY } from '../apolloStore';

const updateUserAvatar =  (_, { id, avatar }, { cache }) => {
  cache.writeFragment({
    id: cache.identify({ __typename: "User", id }),
    fragment: gql`fragment UserAvatar on User { avatar }`,
    data: { avatar },
  });
}


const updateFilterQuery = (_, { query}, { cache }) => {
  cache.writeFragment({
    id: cache.identify({ __typename: "TableFilter" }),
    fragment: gql`fragment FilterQuery on TableFilter {
      filters {
        query
      }
      page
    }`,
    data: { filters: { q:query }, page: 1 },
    });
};

const updateFilterSort = (_, { field, asc}, { cache }) => {
  cache.writeFragment({
    id: cache.identify({ __typename: "TableFilter"  }),
    fragment: gql`fragment FilterSort on TableFilter {
      filters { 
        sort {
          field
          asc
        }
      }
    }`,
    data: { filters: { sort: {field, asc} } },
    });
};

const updateFilterPaging = (_, {page, first}, { cache }) => {
  cache.writeFragment({
    id: cache.identify({ __typename: "TableFilter" }),
    fragment: gql`fragment FilterPaging on TableFilter {
      first
      page
    }`,
    data: { page, first },
    });
};

export default  {
    updateUserAvatar,
    updateFilterQuery,
    updateFilterSort,
    updateFilterPaging
}