export const PAYMENT_STATUS = {
  REQUIRES_CREATION: 'REQUIRES_CREATION', // when user is requested for payment (request payment reminder) ??
  REQUIRES_METHOD: 'REQUIRES_METHOD',
  REQUIRES_CONFIRMATION: 'REQUIRES_CONFIRMATION',
  REQUIRES_ACTION: 'REQUIRES_ACTION',
  REQUIRES_CAPTURE: 'REQUIRES_CAPTURE',
  PROCESSING: 'PROCESSING',
  CANCELED: 'CANCELED',
  SUCCEEDED: 'SUCCEEDED',
  REFUNDED: 'REFUNDED',
  REFUND_FAIL: 'REFUND_FAIL',
  FAILED: 'FAILED',
};

export const OPERATION_STATUS = {
  LOADING: 'lOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const REFUND_REASON = {
  CANCELED:    'CANCELED',
  CANT_ATTEND: 'CANT_ATTEND',
  OTHER:       'OTHER'
};

export const ACCOUNT_HOLDER_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  COMPANY: 'COMPANY',
};

export const ACCOUNT_HOLDER_OPTIONS = [
  {label: "Individual", value:ACCOUNT_HOLDER_TYPES.INDIVIDUAL},
  {label: "Company", value:ACCOUNT_HOLDER_TYPES.COMPANY}

];

export const PAYOUT_ACCOUNT_TYPE = {
  CARD:"CARD",
  BANK_ACCOUNT:"BANK_ACCOUNT"
}

export const PAYMENT_TYPE_ENUM = {
  CARD:"CARD",
  EXTERNAL:"EXTERNAL",
}

export const OPPORTUNITY_PAYMENT = {
  FREE:"FREE",
  PAID_INTERNAL:"PAID_INTERNAL",
  PAID_EXTERNAL:"PAID_EXTERNAL"
}

export const APPLICATION_PAYMENT_TYPE = {
  FREE_COUPON:"FREE_COUPON",
  EXTERNAL_PAYMENT:"EXTERNAL_PAYMENT",
  INTERNAL_PAYMENT:"INTERNAL_PAYMENT",

}

export const currencies = [
  'usd',
  'aed',
  'afn',
  'all',
  'amd',
  'ang',
  'aoa',
  'ars',
  'aud',
  'awg',
  'azn',
  'bam',
  'bbd',
  'bdt',
  'bgn',
  'bif',
  'bmd',
  'bnd',
  'bob',
  'brl',
  'bsd',
  'bwp',
  'bzd',
  'cad',
  'cdf',
  'chf',
  'clp',
  'cny',
  'cop',
  'crc',
  'cve',
  'czk',
  'djf',
  'dkk',
  'dop',
  'dzd',
  'egp',
  'etb',
  'eur',
  'fjd',
  'fkp',
  'gbp',
  'gel',
  'gip',
  'gmd',
  'gnf',
  'gtq',
  'gyd',
  'hkd',
  'hnl',
  'hrk',
  'htg',
  'huf',
  'idr',
  'ils',
  'inr',
  'isk',
  'jod', // TODO: remove it and put it inside a special list (internal currencies and external currencies)
  'jmd',
  'jpy',
  'kes',
  'kgs',
  'khr',
  'kmf',
  'krw',
  'kyd',
  'kzt',
  'lak',
  'lbp',
  'lkr',
  'lrd',
  'lsl',
  'mad',
  'mdl',
  'mga',
  'mkd',
  'mmk',
  'mnt',
  'mop',
  'mro',
  'mur',
  'mvr',
  'mwk',
  'mxn',
  'myr',
  'mzn',
  'nad',
  'ngn',
  'nio',
  'nok',
  'npr',
  'nzd',
  'pab',
  'pen',
  'pgk',
  'php',
  'pkr',
  'pln',
  'pyg',
  'qar',
  'ron',
  'rsd',
  'rub',
  'rwf',
  'sar',
  'sbd',
  'scr',
  'sek',
  'sgd',
  'shp',
  'sll',
  'sos',
  'srd',
  'std',
  'szl',
  'thb',
  'tjs',
  'top',
  'try',
  'ttd',
  'twd',
  'tzs',
  'uah',
  'ugx',
  'uyu',
  'uzs',
  'vnd',
  'vuv',
  'wst',
  'xaf',
  'xcd',
  'xof',
  'xpf',
  'yer',
  'zar',
  'zmw',
];
