import { gql } from '@apollo/client';

export const OPPORTUNITIES_PAYMENT_QUERY = gql`
  query opportunityPayments($slug: String!, $first: Int!, $page: Int) {
    opportunityPayments(opportunitySlug: $slug, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
      }
      data {
        application_id
        name
        fee_title
        amount
        status
        refund_status
        currency
        coupon
        discount
        discount_percent
        created_at
        receipt
        payment_id
      }
    }
  }
`;

export const EXTERNAL_ACCOUNTS = gql`
  query externalAccounts($organisation: ID!) {
    externalAccounts(organisation: $organisation) {
      id
      type
      country
      currency
      default_for_currency
      holder_name
      ... on BankAccountDTO {
        last4
        status
        routing_number
        account_type
      }
    }
  }
`;

export const APPLICATION_RECEIPT = gql`
  query applicationReceipt($receipt_url: String!, $payment_id: String!) {
    applicationReceipt(receipt_url: $receipt_url, payment_id: $payment_id)
  }
`;

export const PAYOUT_CURRENCIES = gql`
  query payoutAccountCurrencies($organisationSlug: String!) {
    payoutAccountCurrencies(organisation: $organisationSlug)
  }
`;