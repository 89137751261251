import React from 'react';
import { v4 as uuid } from 'uuid';
import ShortTextIcon from '@mui/icons-material/ShortText';
// import ListIcon from '@material-ui/icons/List';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkIcon from '@mui/icons-material/Link';
import VideocamIcon from '@mui/icons-material/Videocam';
import ImageIcon from '@mui/icons-material/Image';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PeopleIcon from '@mui/icons-material/People';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Icon from '../components/atoms/Icon';

export const FIELDS_ENUM = {
  TEXT_FIELD: 'text_field',
  EMAIL: 'email',
  TEL: 'tel',
  DATETIME_FIELD: 'datetime_field',
  LINK: 'link',
  VIDEO_LINK: 'video_link',
  IMAGE: 'image',
  AUDIO: 'audio',
  ATTACHMENT: 'attachment',
  INSTRUMENTS: 'instruments',
  GENRES: 'genres',
  LIST: 'list',
  HEADING: 'heading',
  GROUP_MEMBERS: 'group_members',
  TOGGLE: 'toggle',
  LOCATION: 'location',
};

export const SUBTYPE_ENUM = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  DATE: 'date',
  TIME: 'time',
  DATETIME: 'datetime',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  WEBSITE: 'website',
  SOUNDCLOUD: 'soundcloud',
  MULTIPLE: 'multiple',
  SINGLE: 'single',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  H4: 'h4',
  BODY1: 'body1',
  OTHER_INSTRUMENTS: 'other_instruments'
};

export const GROUP_MEMBERS = [
  {
    name: 'Full Name',
    slug: 'full_name',
    type: FIELDS_ENUM.TEXT_FIELD,
    icon: <ShortTextIcon />,
    default: true,
    subtype: SUBTYPE_ENUM.TEXT,
    choices: null,
  },
  {
    name: 'Gender',
    slug: 'gender',
    type: FIELDS_ENUM.LIST,
    default: false,
    icon: <RadioButtonCheckedIcon />,
    subtype: SUBTYPE_ENUM.RADIO,
    choices: ['male', 'female', 'non-binary', 'prefer-not-to-say'],
  },
  {
    name: 'Email',
    slug: 'email',
    type: FIELDS_ENUM.EMAIL,
    default: false,
    icon: <AlternateEmailIcon />,
    subtype: null,
    choices: null,
  },
  {
    name: 'Birthday',
    slug: 'birthday',
    type: FIELDS_ENUM.DATETIME_FIELD,
    default: false,
    icon: <CalendarTodayIcon />,
    subtype: SUBTYPE_ENUM.DATE,
    choices: null,
  },
  {
    name: 'Phone',
    slug: 'phone',
    type: FIELDS_ENUM.TEL,
    default: false,
    icon: <PhoneIcon />,
    subtype: null,
    choices: null,
  },
  {
    name: 'Instruments',
    slug: 'instruments',
    type: FIELDS_ENUM.INSTRUMENTS,
    default: true,
    icon: <ShortTextIcon />,
    subtype: null,
    choices: null,
  },
  {
    name: 'Back Vocals',
    slug: 'back_vocals',
    default: false,
    type: FIELDS_ENUM.TOGGLE,
    icon: <CheckBoxIcon />,
    subtype: null,
    choices: null,
  },
  {
    name: 'Other Instruments',
    slug: 'other_instruments',
    default: true,
    type: FIELDS_ENUM.TEXT_FIELD,
    icon: <ShortTextIcon />,
    subtype: SUBTYPE_ENUM.TEXT,
    choices: null,
  }
];

export const FIELDS = [
  {
    id: uuid(),
    type: FIELDS_ENUM.TEXT_FIELD,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.EMAIL,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.TEL,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.DATETIME_FIELD,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.LINK,
  },
  {
    id: uuid(),

    type: FIELDS_ENUM.VIDEO_LINK,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.IMAGE,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.AUDIO,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.ATTACHMENT,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.INSTRUMENTS,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.GENRES,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.LIST,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.HEADING,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.GROUP_MEMBERS,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.LOCATION,
  },
];

export const FIELD_OPTIONS = {
  [FIELDS_ENUM.TEXT_FIELD]: {
    name: 'Text Field',
    icon: <ShortTextIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXT,
        value: 'text',
      },
      {
        key: SUBTYPE_ENUM.TEXTAREA,
        value: 'textarea',
      },
    ],
  },
  [FIELDS_ENUM.EMAIL]: {
    name: 'Email',
    icon: <AlternateEmailIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.TEL]: {
    name: 'Tel',
    icon: <PhoneIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.DATETIME_FIELD]: {
    name: 'Date and Time',
    icon: <CalendarTodayIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.DATETIME,
        value: 'datetime',
      },
      {
        key: SUBTYPE_ENUM.DATE,
        value: 'date',
      },
      {
        key: SUBTYPE_ENUM.TIME,
        value: 'time',
      },
    ],
  },
  [FIELDS_ENUM.LINK]: {
    name: 'Link',
    icon: <LinkIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.WEBSITE,
        value: 'website',
      },
      {
        key: SUBTYPE_ENUM.FACEBOOK,
        value: 'facebook',
      },
      {
        key: SUBTYPE_ENUM.TWITTER,
        value: 'twitter',
      },
      {
        key: SUBTYPE_ENUM.INSTAGRAM,
        value: 'instagram',
      },
      {
        key: SUBTYPE_ENUM.LINKEDIN,
        value: 'linkedin',
      },
      {
        key: SUBTYPE_ENUM.YOUTUBE,
        value: 'youtube',
      },
      {
        key: SUBTYPE_ENUM.SOUNDCLOUD,
        value: 'soundcloud',
      },
    ],
  },
  [FIELDS_ENUM.VIDEO_LINK]: {
    name: 'Video',
    icon: <VideocamIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.IMAGE]: {
    name: 'Image',
    icon: <ImageIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.SINGLE,
        value: 'single',
      },
      {
        key: SUBTYPE_ENUM.MULTIPLE,
        value: 'multiple',
      },
    ],
  },
  [FIELDS_ENUM.AUDIO]: {
    name: 'Audio',
    icon: <MusicNoteIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.SINGLE,
        value: 'single',
      },
      {
        key: SUBTYPE_ENUM.MULTIPLE,
        value: 'multiple',
      },
    ],
  },
  [FIELDS_ENUM.ATTACHMENT]: {
    name: 'Attachment',
    icon: <AttachFileIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.SINGLE,
        value: 'single',
      },
      // {
      //   key: SUBTYPE_ENUM.MULTIPLE,
      //   value: 'multiple',
      // },
    ],
  },
  [FIELDS_ENUM.INSTRUMENTS]: {
    name: 'Instruments',
    icon: <Icon icon="electric-guitar" />,
    hasChoices: false,
  },
  [FIELDS_ENUM.GENRES]: {
    name: 'Genres',
    icon: <QueueMusicIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.LIST]: {
    name: 'List',
    icon: <CheckBoxIcon />,
    hasChoices: true,
    subtypes: [
      {
        key: SUBTYPE_ENUM.RADIO,
        value: 'radio',
      },
      {
        key: SUBTYPE_ENUM.CHECKBOX,
        value: 'checkbox',
      },
      // {
      //   key: SUBTYPE_ENUM.DROPDOWN,
      //   value: 'dropdown',
      // },
    ],
  },
  [FIELDS_ENUM.HEADING]: {
    name: 'Text',
    icon: <TextFieldsIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.H4,
        value: 'heading',
      },
      {
        key: SUBTYPE_ENUM.BODY1,
        value: 'text',
      },
    ],
  },
  [FIELDS_ENUM.LOCATION]: {
    name: 'Location',
    icon: <LocationOnIcon />,
  },
  [FIELDS_ENUM.GROUP_MEMBERS]: {
    name: 'Group Members',
    icon: <PeopleIcon />,
  },
};

export const INDIVIDUALS_FIXED_FIELDS = [
  {
    id: 'c25de979-5163-48a1-9926-84b421734a15',
    type: 'text_field',
    // label: { en: 'full_name' },
    label: 'full_name',
    is_required: true,
    options: { subtype: SUBTYPE_ENUM.TEXT },
    choices: { en: [] },
    fixed: true,
  },
  {
    id: '2d6b9da4-6133-4541-bcb3-56d2d252e9fd',
    type: 'email',
    // label: { en: 'email' },
    label: 'email',
    is_required: true,
    options: null,
    choices: { en: [] },
    fixed: true,
  },
  {
    id: 'ea1c1d2d-b7ec-4028-ab2f-2af7b75c4d00',
    type: FIELDS_ENUM.LOCATION,
    label: 'address',
    // label: { en: 'address' },

    is_required: true,
    // options: { subtype: SUBTYPE_ENUM.TEXTAREA },
    options: null,
    choices: { en: [] },
    fixed: true,
  },
];

export const BANDS_FIXED_FIELDS = [
  {
    id: 'c25de979-5163-48a1-9926-84b421734a15',
    type: 'text_field',
    // label: { en: 'full_name' },
    label: 'artist_name',
    is_required: true,
    options: { subtype: SUBTYPE_ENUM.TEXT },
    choices: { en: [] },
    fixed: true,
  },
  ...INDIVIDUALS_FIXED_FIELDS,

]


export const SYSTEM_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const OTHER_INSTRUMENTS_LABELS = [
  'Other Instruments',
  'Otros instrumentos',
  'Autres instruments',
  'Andere Instrumente',
  'Muut soittimet',
  'Altri strumenti',
  'Andere instrumenten',
  'Ostali instrumenti',
  'Outros instrumentos',
  'أدوات أخرى',
  'Другие инструменты'
];
