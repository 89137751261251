import {gql} from '@apollo/client'

export const UPGRADE_STATUS = gql`
  query upgradeStatus {
    upgradeStatus {
      id
      status
    }
  }
`;

export const PROFILE_FORM_DATA = gql`
  query {
    profileFormData {
      supportedLanguages {
        slug,
        name,
        is_supported,
      },
      instruments {
        name
        slug
      },
      genres {
        slug,
        name,
      },
      types {
        slug,
        name,
      },
    }
  }
`;