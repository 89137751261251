/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import App from 'next/app';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, CssBaseline } from '@mui/material';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import 'react-multi-carousel/lib/styles.css';
import 'draft-js/dist/Draft.css';

// import 'react-phone-input-2/lib/material.css';

import { DefaultSeo, app } from 'next-seo';
import NProgress from 'nprogress';
import { Router } from 'next/router';
import { initializeApollo, useApollo } from 'config/apollo/apollo-client';

import { getDataFromTree } from '@apollo/react-ssr';
import GlobalStyle, { theme as styledTheme } from '../config/globalStyles';
import materialTheme from '../config/theme';
import { appWithTranslation } from '../config/i18n';
import '../public/modals.css';
import MutationsStatus from '@app/components/molecules/MutationsStatus';
import SnackbarProvider from '@app/providers/SnackbarProvider';
import SpacesProvider from '@app/contexts/SpacesContext';
import SnackNotifications from '@app/components/molecules/SnackNotificationsStatus';
import AuthUserProvider from '@app/contexts/AuthUserContext';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  release: `mubazar@${process.env.npm_package_version}`,
  enabled: process.env.NEXT_PUBLIC_NODE_ENV === 'production',
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  // autoSessionTracking: true,
  integrations: [
    // Sentry.replayIntegration(),
    new Integrations.BrowserTracing(),
  ],
  // Session Replay
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // autoSessionTracking: true

});


const DEFAULT_SEO = {
  title: 'Mubazar',
  description: 'Find music opportunities near you, Opportunities for musicians. All in one place.',
  openGraph: {
    type: 'website',
    locale: 'en_GB',
    url: 'https://www.mubazar.com',
    title: 'Mubazar',
    description:
      'Find music opportunities near you, Opportunities for musicians. All in one place.',
    images: [
      {
        url: 'https://www.mubazar.com/images/mubazar-logo-BETA-RED.png',
        width: '400',
        height: 400,
      },
    ],
    site_name: 'Mubazar',
    // imageWidth: 1200,
    // imageHeight: 1200,
  },
  twitter: {
    // handle: '@garmeeh',
    cardType: 'summary_large_image',
  },
};


function MyApp({ Component, pageProps, err }) {

  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    const handleRouteChange = (url) => {
      NProgress.start();
    };
    const handleRouteEnd = (url) => {
      NProgress.done();
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    Router.events.on('routeChangeStart', handleRouteChange);
    Router.events.on('routeChangeComplete', handleRouteEnd);
    Router.events.on('routeChangeError', handleRouteEnd);

    return () => {
      Router.events.off('routeChangeStart', () => 1);
      Router.events.off('routeChangeComplete', () => 1);
      Router.events.off('routeChangeError', () => 1);
    };
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <StyledThemeProvider theme={styledTheme}>
        <GlobalStyle />
        <ThemeProvider theme={materialTheme}>
        <AuthUserProvider>
          <SnackbarProvider>
            <SpacesProvider>
              <CssBaseline />
              <MutationsStatus />
              <DefaultSeo {...DEFAULT_SEO} />
              <Component {...pageProps} err={err} />
            </SpacesProvider>
          </SnackbarProvider>
        </AuthUserProvider>
        </ThemeProvider>
      </StyledThemeProvider>
    </ApolloProvider>
  );
}


export default appWithTranslation(MyApp);
