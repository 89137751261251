import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import { Parent } from './style';

const Icon = ({
  width,
  height,
  iconWidth,
  iconHeight,
  color,
  bgColor,
  circle,
  icon,
  onClick,
  flipH,
  ...rest
}) => (
    <Parent
      parentWidth={width}
      parentHeight={height}
      svgColor={color}
      bgColor={bgColor}
      circle={circle}
      onClick={onClick}
      flipH={flipH}
      {...rest}
    >
      <Image
        svg
        // eslint-disable-next-line import/no-dynamic-require
        // src={`../../../images/icons/${icon}.svg`}
        src={`/images/icons/${icon}.svg`}
        width={iconWidth || width}
        height={iconHeight || height}
        color={color}
      />
    </Parent>
  );

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  bgColor: PropTypes.string,
  circle: PropTypes.bool,
  onClick: PropTypes.func,
  flipH: PropTypes.string,
};

Icon.defaultProps = {
  color: null,
  flipH: null,
  // width: '1.7142857142857142rem',
  // height: '1.7142857142857142rem',  
  width: undefined,
  height:undefined,
  iconWidth: null,
  iconHeight: null,
  bgColor: null,
  circle: false,
  onClick: null,
};

export default Icon;
