import React, { createContext, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { USER_QUERY } from '@app/apollo/queries';

const AuthUserContext = createContext();

const AuthUserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [formattedAuthUser, setFormattedAuthUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [authUserQuery, { loading: loadingUser }] = useLazyQuery(USER_QUERY);

  const fetchUser = async () => {
    await authUserQuery({
      onCompleted: (data) => {
        if (data) {
          setUser(data.me);
          setIsAuthenticated(!!data.me)
        }
      }
    })
  }

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    if(user) {
      setFormattedAuthUser({
        id: user.id,
        name: user.name,
        email: user.email,
        avatar: user.avatar
      })
    }
  }, [user])

  const reloadAuthUser = () => {
    fetchUser()
  }

  
  return (
    <AuthUserContext.Provider
      value={{ user, loadingUser, isAuthenticated, formattedAuthUser, fetchUser, reloadAuthUser, setUser }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

export {AuthUserContext};

export default AuthUserProvider;
