const NextI18Next = require('next-i18next').default;
// const { detectUserLanguage } = require('../helpers/detectUserLanguage');

const supportedLanguages = ['es', 'de', 'fr', 'nl', 'it', 'pt', 'hr', 'ar', 'ru', 'fi', 'lt'];
// const path = require('path')

module.exports = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: supportedLanguages,
  browserLanguageDetection: true,
  localeSubpaths: {
    en: 'en',
    fr: 'fr',
    es: 'es',
    de: 'de',
    it: 'it',
    nl: 'nl',
    pt: 'pt',
    hr: 'hr',
    ar: 'ar',
    ru: 'ru',
    fi: 'fi',
    lt: 'lt',
  },
  // localePath: path.resolve('./public/static/locales'),
  react: { useSuspense: false },
  ignoreRoutes: ['/_next/', '/static/', '/public/'],
  fallbackLng: false, // Disable fallback language
  // debug: true, // Enable debug mode
  // saveMissing: true, // Enable saving missing keys
  // missingKeyHandler(lng, ns, key, fallbackValue) {
  //   console.warn(`Missing translation key: ${key}`);
  // },
});
