import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
import { colors } from './colors';

const palette = {
  primary: colors.primary,
  secondary: colors.secondary,
  grey: colors.grey,
  
};
const themeName = 'Mubazar Theme';

// const breakpoints = {
//   keys: [
//     "xs","sm","md","lg","xs",
//   ],
//   values: {"xs":0,"sm":600, "md":960, "lg":1280, "xl":1920}
// }

const typography = {
  fontFamily: [
    'Source Sans Pro',
    'Roboto',
    'Montserrat',
    'Questrial',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontStyle: 'normal',
  fontSize: 16,
  htmlFontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: 'Montserrat',
    fontWeight: 900,
    fontSize: '5rem',
    lineHeight: '1.075',
    // letterSpacing: '-.015625em',
    letterSpacing: '-0.0938rem',
    color: colors.grey[700],
    // [breakpoints.up('md')]: {
    //   fontSize: '2.4rem',
    // },
  },
  h2: {
    // fontFamily: '"Roboto Slab", serif',
    fontWeight: 'bold',
    fontSize: '48px',
    lineHeight: '1.08333333333',
    // letterSpacing: '-0.0083333em',
    letterSpacing: '-0.0313rem',
    color: colors.secondaryColor[700],
  },
  h3: {
    // fontFamily: '"Roboto Slab", serif',
    fontWeight: 'normal',
    fontSize: '2.375rem',
    lineHeight: '1.10526315789',
    letterSpacing: 'normal',
    color: colors.secondaryColor[700],
  },
  h4: {
    // fontFamily: '"Roboto Slab", serif',
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: '1.125',
    // letterSpacing: '.0073529412rem',
    letterSpacing: '0.0156rem',
    color: colors.secondaryColor[700],
  },
  h5: {
    // fontFamily: '"Roboto Slab", serif',
    fontWeight: '700',
    fontSize: '1.5rem',
    lineHeight: '1.16666666667',
    letterSpacing: 'normal',
    color: colors.secondaryColor[600],
  },
  h6: {
    // fontFamily: '"Roboto Slab", serif',
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.25',
    // letterSpacing: '.0125rem',
    letterSpacing: '0.0094rem',
    textTransform: 'uppercase',
  },
  subtitle1: {
    fontWeight: 'normal',
    fontSize: '1.125rem',
    lineHeight: '1.33333333333',
    // letterSpacing: '0.009375rem',
    letterSpacing: '0.0094rem',
  },
  subtitle2: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '1.25',
    letterSpacing: '0.0625rem',
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
    // lineHeight: '1.25',
    lineHeight: '1.5rem',
    letterSpacing: '0.0313rem',
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.42857142857',
    // letterSpacing: '0.0178571429rem',
    letterSpacing: '0.0156rem',
  },
  button: {
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: '1.8',
    // letterSpacing: '0.078125rem',
    letterSpacing: '0.0938rem',
    textTransform: 'uppercase',
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.14285714286',
    letterSpacing: '0.025rem',
  },
  overline: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '1.33333333333',
    // letterSpacing: '0.09375rem',
    letterSpacing: '0.0938rem',
    textTransform: 'uppercase',
  },
  textTransform: 'none',
};

const shadows = [
  'none',
  '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
  '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
  '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
  '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
  '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
  '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px…gba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
  '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px…gba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px…gba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px…gba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
  '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px…gba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
  '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px…gba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
  '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2p…gba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
  '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2p…gba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
  '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2p…gba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
  '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2p…gba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3…gba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3…gba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
  '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3…gba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
  '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3…gba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
  '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3…gba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
];

const overrides = {
  // Style sheet name ⚛️
  MuiButton: {
    // Name of the rule
    // label: {
    //   textTransform: 'none',
    // },
    root: {
      padding: '6px 20px',
      borderRadius: '6px',
    },
  },
  MuiLink: {
    underlineHover: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  MuiAutocomplete: {
    tag: {
      '&&.MuiChip-root': {
        backgroundColor: colors.primary.main,
        color: colors.white,
      },
    },
  },
  MuiFormControl: {
    root: {
      marginBottom: '1rem',
    },
  },
  MuiFab: {
    extended: {
      padding: "0 32px"
    }
  }
};
let theme = createMuiTheme({ palette, themeName, typography, overrides, shadows });

theme = responsiveFontSizes(theme);
export default { ...theme };
