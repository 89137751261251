export const initialData = {
  sidebarOpen: false,
  networkStatus: {
    __typename: 'NetworkStatus',
    isConnected: false,
  },
  applicationFilters: {
    __typename: 'TableFilter',
    filters: {
      q: null,
      sort: {
        field: 'RECENT',
        asc: false,
      },
    },
    first: 10,
    page: 1,
  },
  dashboardFilters: {
    __typename: 'TableFilter',
    filters: {
      q: null,
      sort: {
        field: 'RECENT',
        asc: false,
      },
    },
    first: 50,
    page: 1,
  },
  refundRequestsFilters: {
    __typename: 'TableFilter',
    first: 10,
    page: 1,
  },
};
