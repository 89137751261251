

// this is statuses used only in refund i hope :))))
export  const ACTIVITY_STATUS = {
    REQUESTED  : 'REQUESTED',
    APPROVED   : 'APPROVED',
    DECLINED   : 'DECLINED',
    PROCESSING : 'PROCESSING',
    CANCELED   : 'CANCELED',
    FAILED     : 'FAILED',
    SUCCEEDED  : 'SUCCEEDED'
};